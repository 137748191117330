<template>
  <mobile-screen
    :header="true"
    screen-class="gray-bg show-footer-only-on-last-screen company-documents-screen"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="about-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params,
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("notifications.notifications.send-to") }}
        </div>
        <template v-slot:right> </template>
      </top-header-menu-wrapper>
    </template>
    <section>
      <ul class="clebex-item-section pill">
        <li class="clebex-item-section-item">
          <button class="clebex-item-content-wrapper" @click="selectAllUsers()">
            <span
              class="label"
              style="display: flex; flex-direction: row !important;"
            >
              <span class="highlight" style="flex: 1;">All users</span>
              <span class="follow-up-icon-item" v-if="allUsersSelected">
                <icon icon="#cx-app1-checkmark" style="fill: #02be02;"></icon>
              </span>
            </span>
          </button>
        </li>
        <li class="clebex-item-section-item">
          <button
            class="clebex-item-content-wrapper"
            @click="selectUserContainers()"
          >
            <span
              class="label"
              style="display: flex; flex-direction: row !important;"
            >
              <span class="highlight" style="flex: 1;"
                >User containers
                <span v-if="userContainers && userContainers.length"
                  >({{ userContainers.length }})</span
                ></span
              >
              <span class="follow-up-icon-item" v-if="userContainersSelected">
                <icon icon="#cx-app1-checkmark" style="fill: #02be02;"></icon>
              </span>
              <span class="follow-up-icon-item"
                ><icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"/></span
            ></span>
          </button>
        </li>
        <li class="clebex-item-section-item">
          <button class="clebex-item-content-wrapper" @click="selectUsers()">
            <span
              class="label"
              style="display: flex; flex-direction: row !important;"
            >
              <span class="highlight" style="flex: 1;"
                >Users
                <span v-if="users && users.length"
                  >({{ users.length }})</span
                ></span
              >
              <span class="follow-up-icon-item" v-if="usersSelected">
                <icon icon="#cx-app1-checkmark" style="fill: #02be02;"></icon>
              </span>
              <span class="follow-up-icon-item"
                ><icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"/></span
            ></span>
          </button>
        </li>
      </ul>
    </section>
    <template v-slot:footer> </template>
  </mobile-screen>
  <router-view></router-view>
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
// import EditCompanyDocument from "./EditCompanyDocument.vue";
import paginationMixin from "@/services/mixins/pagination/pagination-mixin";

export default {
  name: "CompanyDocuments",
  mixins: [paginationMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
  },
  data() {
    return {
      allUsersSelected: false,
      userContainersSelected: false,
      usersSelected: false,
    };
  },
  created() {
    if (this.allUsers) {
      this.allUsersSelected = true;
    } else if (this.userContainers.length) {
      this.userContainersSelected = true;
    } else if (this.users.length) {
      this.usersSelected = true;
    }
  },
  computed: {
    ...mapState("companyNotifications", [
      "allUsers",
      "userContainers",
      "users",
    ]),
  },
  methods: {
    ...mapActions("companyNotifications", [
      "setAllUsers",
      "setUserContainers",
      "setUsers",
      "setSendTo",
    ]),
    selectAllUsers() {
      this.allUsersSelected = true;
      this.userContainersSelected = false;
      this.usersSelected = false;
      this.setUsers([]);
      this.setUserContainers([]);
      this.setAllUsers(true);
      this.setSendTo(
        this.displayLabelName("notifications.notifications.all-users")
      );
    },
    selectUserContainers() {
      this.allUsersSelected = false;
      this.userContainersSelected = true;
      this.usersSelected = false;
      // this.setSendTo(false);
      if (this.$route.name.includes("edit")) {
        this.$router.push({
          name: "r_edit-company-notification-send-to-user-containers",
        });
      } else {
        this.$router.push({
          name: "r_add-company-notification-send-to-user-containers",
        });
      }
    },
    selectUsers() {
      this.allUsersSelected = false;
      this.userContainersSelected = false;
      this.usersSelected = true;
      // this.setSendTo(false);
      if (this.$route.name.includes("edit")) {
        this.$router.push({
          name: "r_edit-company-notification-send-to-users",
        });
      } else {
        this.$router.push({
          name: "r_add-company-notification-send-to-users",
        });
      }
    },
  },
  props: {
    backLinkName: {
      type: String,
      required: true,
    },
  },
};
</script>
<style>
.deleted-count {
  position: relative;
  top: -4px;
  margin: 5px 8px;
  padding-left: 0px !important;
  margin-left: 0px;
}
</style>
